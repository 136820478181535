import { IAdvertising } from "api/types/_advertising";
import { Option } from "api/types/_public";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownAdvertising from "components/Common/DropdownAdvertising";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import { useRole } from "components/Hooks/UserHooks";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { ARR_INDEX_TEXT_DAY_OF_WEEK } from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CountUp from "react-countup";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { getAllAdvertising, advertisingReports as onGetReportAdvertising } from "../../../store/thunks";
import ChartLine from "./ChartLine";


const SORT_BY_DEFAULT = 'date';
const ORDER_BY_DEFAULT = 'DESC';

registerLocale('en', en);
registerLocale('ko', ko);

const ReportAdvertising = () => {
    const { t, i18n } = useTranslation();
    const { userPermissions } = useRole();
    const navigate = useNavigate();
    const [query, setQuery]: any = useQueryParams({
        time_request: withDefault(NumberParam, 0),
        page: withDefault(NumberParam, 1),
        limit: withDefault(NumberParam, 100),
        sort_by: withDefault(StringParam, SORT_BY_DEFAULT),
        order_by: withDefault(StringParam, ORDER_BY_DEFAULT),
        start_date: withDefault(StringParam, `${moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")}`),
        end_date: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    });

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Report;

    const ReportProperties = createSelector(
        selectLayoutState,
        (state) => ({
            advertising: state.advertising,
            isAdvertisingSuccess: state.isAdvertisingSuccess,
            isAdvertisingLoading: state.isAdvertisingLoading,
            error: state.error,
        })
    );

    const { advertising, isAdvertisingLoading } = useSelector(ReportProperties);

    const [trackingCodeSearch, setTrackingCodeSearch] = useState<string>(query?.tracking_code || "");

    const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date ? moment(query?.start_date, 'Y-MM-DD').toDate() : null, query?.end_date ? moment(query?.end_date || '', 'Y-MM-DD').toDate() : null]);
    const [startDate, endDate] = dateSearch;


    const [listAdvertising, setListAdvertising] = useState<IAdvertising[]>([]);
    const [advertisingSearch, setAdvertisingSearch] = useState<Option | null>({
        label: listAdvertising?.find((item) => String(item?.id || '') === String(query?.advertising_id || ''))?.title || t('All Advertising'),
        value: `${listAdvertising?.find((item) => String(item?.id || '') === String(query?.advertising_id || ''))?.id || ''}`
    });

    useEffect(() => {
        dispatch(onGetReportAdvertising(query));
    }, [JSON.stringify(query)]);

    const searchData = () => {
        setQuery({
            ...query,
            page: 1,
            start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
            end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
            advertising_id: advertisingSearch?.value || '',
            time_request: + new Date()
        });
    };

    const resetData = () => {
        const queryNew = {
            ...query,
            start_date: `${moment(new Date(new Date().setDate(new Date().getDate() - 30))).format("Y-MM-DD")}`,
            end_date: `${moment(new Date()).format("Y-MM-DD")}`,
            advertising_id: '',
            sort_by: SORT_BY_DEFAULT,
            order_by: ORDER_BY_DEFAULT,
            time_request: + new Date(),
            page: 1,
        };
        setQuery(queryNew, "push");
        setDateSearch([moment(queryNew?.start_date || "", 'Y-MM-DD').toDate(), moment(queryNew?.end_date || "", 'Y-MM-DD').toDate()]);
        setAdvertisingSearch({ label: t('All Advertising'), value: '' });
    };

    // Column
    const columns = useMemo(
        () => [
            {
                Header: t('Date'),
                accessor: "date",
                filterable: true,
                sortable: true,
                thWidth: 150,
                thClass: 'align-middle text-start',
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    const arrDate = String(cell?.value || '').split(' ');
                    const num = moment(cell?.value).days(); // 0 is Sunday , 1 is Monday, ... , 6 is Saturday
                    const classText = num === 0 ? 'text-danger row-bg-danger' : (num === 6 ? 'text-primary row-bg-primary' : '');
                    const textNote = t(`STANDS_${ARR_INDEX_TEXT_DAY_OF_WEEK[num]}`);
                    return (
                        !!item?.text_row ? <strong>{item?.text_row} </strong> :
                            <div className="text-start" style={{ minWidth: '120px' }}>
                                <span className={classText}>{moment(arrDate?.[0] || '', 'Y-MM-DD').format("MM-DD")} ({textNote})</span>
                            </div>

                    )
                },
            },
            // {
            //     Header: t('Impression'),
            //     accessor: "impression",
            //     filterable: true,
            //     sortable: true,
            //     thClass: 'align-middle text-end',
            //     thWidth: 120,
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         return (
            //             <>
            //                 <div className="text-end pe-3 " style={{ minWidth: '100px' }}>{formatNumberWithCommas(cell?.value)}</div>
            //             </>
            //         )
            //     },
            // },
            {
                Header: t('Click'),
                accessor: "click",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '140px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: t('Expense'),
                accessor: "expense",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>
                            <div className="text-end pe-3" style={{ minWidth: '140px' }}>{formatNumberWithCommas(cell?.value)}</div>
                        </>
                    )
                },
            },
            {
                Header: '',
                accessor: "spance",
                filterable: false,
                sortable: true,
                thClass: 'align-middle text-end',
                thWidth: 200,
                Cell: (cell: any) => {
                    const item = cell?.row?.original;
                    return (
                        <>

                        </>
                    )
                },
            },
            // {
            //     Header: t('CTR'),
            //     accessor: "ctr",
            //     filterable: false,
            //     sortable: true,
            //     thClass: 'align-middle text-end',
            //     thWidth: 140,
            //     Cell: (cell: any) => {
            //         const item = cell?.row?.original;
            //         return (
            //             <>
            //                 <div className="text-end pe-3 " style={{ minWidth: '130px' }}>{formatNumberWithCommas(cell?.value)}</div>
            //             </>
            //         )
            //     },
            // },
        ],
        [i18n?.language, userPermissions]
    );

    const handleChangePage = useCallback((page: any) => {
        setQuery({ page: page + 1 })
    }, []);

    const handleChangeSorting = useCallback((sortBy: any) => {
        setQuery((_prev: any) => {
            return ({ ..._prev, ...sortBy });
        });
    }, []);

    const handleChangePicker = (values: any[] = []) => {
        setDateSearch((_prev: any) => values);
    }

    useEffect(() => {
        const handleCallAllOption = async () => {
            try {
                const [resAdvertising]: any = await Promise.all([getAllAdvertising()]);
                const list_a = ([{ title: t('All Advertising'), id: '' } as any]).concat(resAdvertising?.data || []);
                const advertising = list_a?.find((item) => String(item?.id || '') === String(query?.advertising_id || '')) || null;
                setListAdvertising((_prev) => list_a);
                setAdvertisingSearch((_prev) => ({
                    label: advertising?.name || t('All Advertising'),
                    value: advertising?.id || '',
                }));
            } catch (error: any) {
                return error;
            }
        };
        handleCallAllOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.title = `${t("Report Advertising")} | AdsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    const categories = useMemo(() => {
        return advertising?.list?.map((item: any) => item?.date).reverse() || [];
    }, [advertising?.list, i18n?.language]);

    const series = useMemo(() => {
        const obChart = advertising?.list?.reduce((ob: any, item: any) => {
            ob.click?.push(Number(String(item?.click).replace(/,/g, '')));
            return ob;
        }, { click: [] }) || [];
        return (
            [
                {
                    name: t('Click'),
                    type: 'column',
                    data: obChart?.click?.reverse(),
                },
            ]
        )
    }, [advertising?.list, i18n?.language]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Report Advertising')} pageTitle={t('Report Advertising')} />
                    {isHavePermissionRole(ROLES_FOR_APP.REPORT_ADVERTISING, userPermissions) && (
                        <Row>
                            <Col sm={12} md={12}>
                                <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }} className="ribbon-box right">
                                    <div className="ribbon round-shape" style={{ background: 'rgb(40 99 213)', fontWeight: 300, fontSize: '0.8rem', top: '11px' }}>{t('This data is analyzed per 10 mins')}</div>
                                    <CardHeader className="border-0" style={{ borderRadius: '30px' }}>
                                        <Row className="g-4 align-items-center">
                                            <div className="col-sm">
                                                <div>
                                                    <h5 className="card-title mb-0">
                                                        <span className="me-2">{t('Total')}:</span>
                                                        <CountUp
                                                            start={0}
                                                            end={advertising?.pagination?.total || 0}
                                                            duration={1}
                                                            className="text-primary"
                                                        />
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardHeader>
                                    <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                                        <Row className="g-4 align-items-center mt-0">
                                            <Col sm={6} md={6} xl={2} xxl={2} className='mt-3 mt-sm-3 mt-md-2'>
                                                <LabelWrapper label={t('Advertising')} isShow={true}>
                                                    <DropdownAdvertising
                                                        name="advertising"
                                                        isMulti={false}
                                                        dataList={listAdvertising}
                                                        initialValue={advertisingSearch}
                                                        placeholder={t('Advertising')}
                                                        onChangeSelect={(event: any) => {
                                                            setAdvertisingSearch((_prev) => event);
                                                        }}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={6} md={3} xl={3} xxl={3} className='date-picker-wrapper-custom mt-3 mt-md-2'>
                                                <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
                                                    <DatePickerCustom
                                                        placeholder={`${t('Start Date')} - ${t('End Date')}`}
                                                        startDate={startDate || null}
                                                        endDate={endDate || null}
                                                        onChangePicker={handleChangePicker}
                                                        showOptions={[
                                                            'today', 'yesterday', 'two_day_ago',
                                                            'last_3_days', 'last_7_days', 'last_14_days', 'last_30_days',
                                                            'this_week', 'last_week',
                                                            'this_month', 'last_month',
                                                        ]}
                                                    />
                                                </LabelWrapper>
                                            </Col>
                                            <Col sm={12} md={6} xl={6} xxl={6} className="hstack gap-1 justify-content-center justify-content-md-between mt-3 mt-md-2">
                                                <div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary me-2 "
                                                        onClick={searchData}
                                                        disabled={isAdvertisingLoading}
                                                    >
                                                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                                                        {t('Button Search')}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary fs-14"
                                                        onClick={resetData}
                                                    >
                                                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                                        {t('Button Reset')}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <ChartLine
                                        className="my-3"
                                        titles={[t('Click')]}
                                        categories={categories}
                                        series={series}
                                    />
                                    <CardBody className="pt-3">
                                        <TableContainer
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="align-middle table-bordered-dashed table-have-first-row-is-total"
                                            theadClass="table-light text-muted"
                                            columns={columns}
                                            data={advertising?.list?.length ? (
                                                [
                                                    {
                                                        ...advertising?.total,
                                                        text_row: t('Sum')
                                                    },
                                                    ...advertising?.list]
                                            ) : []}
                                            customPageSize={query.limit}
                                            customPageIndex={query.page - 1}
                                            totalRecords={advertising?.pagination?.total}
                                            customPageCount={Math.ceil(Number(advertising?.pagination?.total) / Number(advertising?.pagination?.limit))}
                                            handleChangePage={handleChangePage}
                                            manualSorting={true}
                                            sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                                            handleChangeSorting={handleChangeSorting}
                                            isLoading={isAdvertisingLoading}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    <ToastContainer closeButton={false} limit={1} />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ReportAdvertising;
