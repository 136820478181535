import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import { IAdvertisingReport } from "./types/_report";
const api = new APIClient();

const path = '/reports';
const reportApi = {
  advertisingReports(params: any): Promise<AxiosResponse<ResponseData<IAdvertisingReport[]> & PaginationResponse, any>> {
    const url = `${path}/advertising`
    return api.get(url, params)
  },
}
export default reportApi
